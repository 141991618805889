/**
 * @file
 * Styles for product themes
*/

@import "variables";

// Filter by Theme Block
#block-facetapi-hg3wmtxbqdlmem5j1trqwokhhgmxy1xw--2 {
  padding-right: 20px;
  
  @media only screen and (max-width: 767px) {
    padding-right: 0;
  }

  .item-list ul {
    margin: 0;
    @include column-count(7);
    @include column-gap(0);
    overflow: hidden;
    
    @media only screen and (min-width: 1800px) {
      @include column-count(8);
    }

    @media only screen and (max-width: 1800px) {
      @include column-count(6);
    }
    
    @media only screen and (max-width: 1500px) {
      @include column-count(5);
    }
    
    @media only screen and (max-width: 1200px) {
      @include column-count(4);
    } 
        
    @media only screen and (max-width: 1000px) {
      @include column-count(3);
    }
    
    @media only screen and (max-width: 830px) {
      @include column-count(2);
    }
    
    @media only screen and (max-width: 767px) {
      @include column-count(4);
    } 
    
    @media only screen and (max-width: 700px) {
      @include column-count(3);
    }
    
    @media only screen and (max-width: 500px) {
      @include column-count(2);
    }
    
    @media only screen and (max-width: 400px) {
      @include column-count(1);
    }
        
    li {
      margin: 0;
      list-style: none;
      padding: 0 5px 10px;
      display: inline-block;
      width: 100%;
      
      // Hackish way to hide active facet
      //color: transparent;
      //font-size: 0;
      
      /*@media only screen and (min-width: 1800px) {
        width: 16.666%;
      }

      @media only screen and (max-width: 1800px) {
        width: 25%;
      }
      
      @media only screen and (max-width: 1500px) {
        width: 33.333%;
      }
      
      @media only screen and (max-width: 900px) {
        width: 100%;
        padding: 0 0 10px;
      }
      
      @media only screen and (max-width: 1200px), (min-width: 575px) and (max-width: 767px) {
        width: 50%;
        padding: 0 5px 10px;
      } 
      
      @media only screen and (max-width: 574px) {
        width: 100%;
        padding: 0 0 10px;
      }*/

      a {
        background: #eee;
        padding: 9px 10px;
        @include border-radius(2px);
        display: block;
        color: $red;
        //font-size: 14px;
        
        &:before {
          content: "e"; 
          font-family: fontello;
          margin-right: 7px;
          font-size: 18px;
          position: relative;
          top: 2px;
        }
        
        &:hover {
          text-decoration: none;
          background: $orange;
          color: white;
          
          &:before {
            content: "E";         
          }
        }
        
        &.facetapi-active {
          display: none;
        }
      }
      
      span.facet-text {
        padding: 9px 10px;
        background: #f9f9f9;
        color: #999;
        @include border-radius(3px);
        display: block;
        
        &:before {
          content: "E"; 
          font-family: fontello;
          margin-right: 7px;
          font-size: 18px;
          position: relative;
          top: 1px;
        }
      }
    } 
  }
}

html.lt-ie10, html.lt-ie9 { // IE9, IE8
  // Filter by Theme Block
  #block-facetapi-hg3wmtxbqdlmem5j1trqwokhhgmxy1xw--2 {
  
    .item-list ul {
      margin: 0;
          
      li {
        float: left;
        width: 20%;

        @media only screen and (max-width: 1800px) {
          width: 16.666%;
          //@include column-count(6);
        }
        
        @media only screen and (max-width: 1500px) {
          width: 20%;
        }
        
        @media only screen and (max-width: 1200px) {
          width: 25%;
        } 
            
        @media only screen and (max-width: 1000px) {
          width: 33.333%;
        }
        
        @media only screen and (max-width: 830px) {
          width: 50%;
        }
        
        @media only screen and (max-width: 767px) {
          width: 25%;
        } 
        
        @media only screen and (max-width: 700px) {
          width: 33.333%;
        }
        
        @media only screen and (max-width: 500px) {
          width: 50%;
        }
        
        @media only screen and (max-width: 400px) {
          width: 100%;
        }

      } 
      
    }
  
  }
}